<template>
  <div class="organizations-create">
    <Banner>
      <div class="banner-content">
        <MeepIconChat class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.chat") }}</span>
      </div>
    </Banner>
    <PageHeader
      :has-back="true"
      title="Ajouter une actualité du cabinet"
      @back="goBack"
    />
    <div class="organizations-create-content page-layout">
      <LoadingCard v-if="isLoading" />

      <md-card v-if="!isLoading" class="meep-form">
        <!-- le formulaire de création -->
        <md-card-content>
          <div class="meep-form-column">
            <!-- Nom -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('title') }">
                <label>Titre de l'actualité*</label>

                <md-input
                  v-model="form.title"
                  v-validate="'required'"
                  name="title"
                  type="text"
                />

                <span v-show="errors.has('title')" class="md-error">
                  {{ errors.first("title") }}
                </span>
              </md-field>
            </div>

            <!-- Logo -->
            <div class="meep-input--file">
              <label for="files">
                {{ $t("organization-news.create.upload-text") }}
              </label>

              <input
                id="files"
                accept="image/*"
                name="files"
                onclick="this.value = null;"
                type="file"
                @change="onFileAdded"
              />
            </div>

            <img
              v-show="fileToUpload"
              :src="imgSrc"
              class="organizations-create-preview-image"
            />

            <!-- Description -->
            <div class="meep-textarea">
              <ckeditor
                ref="ckeditor"
                v-model="form.description"
                :config="editorConfig"
                :editor="editor"
                class="cropper-img"
                placeholder="Description"
                tag-name="textarea"
              />
            </div>

            <span v-show="errors.has('description')" class="md-error">{{
                errors.first("description")
              }}</span>

            <!-- Lien -->

            <div class="meep-input">
              <md-field>
                <label> Lien </label>

                <md-input v-model="form.link" name="link" type="url" />
              </md-field>
            </div>

            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('scope') }">
                <label>Pour *</label>
                <md-select v-model="form.scope">
                  <md-option value="0">Tous</md-option>
                  <md-option value="2">Collaborateur</md-option>
                  <md-option value="4">Client</md-option>
                </md-select>
                <span v-show="errors.has('scope')" class="md-error">
                  {{ errors.first("scope") }}
                </span>
              </md-field>
            </div>
          </div>

          <div class="meep-form-column">
            <!-- Date d'envoi -->
            <md-switch v-model="form.sendLater">
              {{ $t("meep-form.date-text") }}
            </md-switch>

            <template v-if="form.sendLater">
              <md-datepicker v-model="form.date">
                <label> Date à d'envoi </label>
              </md-datepicker>
            </template>

            <md-switch v-if="form.sendLater" v-model="form.sendHourLater">
              {{ $t("meep-form.hour-text") }}
            </md-switch>

            <div
              v-if="form.sendLater && form.sendHourLater"
              class="meep-input meep-time"
            >
              <label class="meep-label"> Heure d'envoi </label>
              <vue-timepicker v-model="form.hour" format="HH:mm" />
            </div>

            <div class="md-layout md-alignment-center-right">
              <md-button
                class="md-raised md-primary meep-form__submit-btn"
                @click.native="createNews"
              >
                {{ $t("organization-news.create.button-text") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import organizationsNews from "../../model/organizationsNews.js";
import filesModel from "../../model/files.js";
import VueTimepicker from "vue2-timepicker";
import PageHeader from "@/components/PageHeader";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LoadingCard from "@/components/LoadingCard";
import CKEditorConfig from "@/mixins/CKEditorConfig";
import "../../styles/cropper.min.css";

import Banner from "@/components/Banner";
import MeepIconChat from "@/components/icons/MeepIconChat.vue";

export default {
  name: "CreateOrganizationNews",

  components: {
    LoadingCard,
    Banner,
    MeepIconChat,
    ckeditor: CKEditor.component,
    PageHeader,
    "vue-timepicker": VueTimepicker,
  },

  mixins: [CKEditorConfig],

  data() {
    return {
      isDebug: false,
      fileToUpload: null,
      imgSrc: "",
      form: {
        title: "",
        description: "",
        link: "",
        scope: 0,
        logo: null,
        sendLater: false,
        sendHourLater: false,
        date: new Date(),
        hour: {
          HH: "00",
          mm: "00",
        },
      },
      isLoading: false,
      editor: ClassicEditor,
      showTopButton: true,
    };
  },

  methods: {
    goBack() {
      window.history.back();
    },

    createNews() {
      this.isLoading = true;
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.isDateInvalid(this.form.date, this.form.hour)) {
            this.$toasted.global.AppError({
              message:
                "Vous ne pouvez pas choisir une date et une heure antérieur à maintenant",
            });

            return;
          }
          if (this.fileToUpload) {
            filesModel.uploadImage(this.fileToUpload).then(file => {
              this.form.logo = file.fileName;
              organizationsNews
                .create(this.form)
                .then(() => {
                  this.$router.push(
                    "/dashboard/communicate/organization-news/",
                  );

                  window.history.back();
                  this.isLoading = false;
                })
                .catch(err => {
                  this.$toasted.global.AppError({
                    message: err.msg,
                  });
                  this.isLoading = false;
                });
            });
          } else {
            organizationsNews
              .create(this.form)
              .then(() => {
                this.$toasted.global.AppSucces({
                  message: this.$t("organization-news.create.success"),
                });

                this.$router.push("/dashboard/communicate/organization-news/");
                this.isLoading = false;
              })
              .catch(err => {
                this.$toasted.global.AppError({
                  message: err.msg,
                });
                this.isLoading = false;
              });
          }
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
          this.isLoading = false;
        }
      });
    },

    isDateInvalid(date, hour) {
      const today = new Date();
      if (this.form.sendLater) {
        date.setHours(hour.HH, hour.mm);
      } else {
        today.setHours(0, 0, 0, 0);
      }
      return date < today;
    },

    async onFileAdded(e) {
      if (!e.target.files.length) {
        return;
      }
      this.fileToUpload = e.target.files[0];
      const reader = new FileReader();
      reader.onload = event => {
        this.imgSrc = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload);
      //this.form.logo = logo;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variable.scss";

#app {
  .organizations-create {
    .logo-preview {
      width: 220px;
      height: 128px;
    }

    .meep-form {
      &.md-card {
        .md-card-content {
          .md-field {
            &.md-datepicker {
              margin-top: 0px;
              padding-top: 20px;

              label {
                top: 10px;
                left: 2px;
                display: block;
                font-size: 12px;
              }
            }
          }

          .meep-time {
            padding: 0 toRem(12);
            height: 48px;
            display: flex;
            align-items: center;
            @include for-lg {
              height: 50px;
            }

            .time-picker {
              margin-top: toRem(15);

              input {
                font-size: toRem(12);
                @include for-lg {
                  font-size: toRem(17);
                }
              }
            }

            label {
              position: absolute;
              top: 5px;
              font-size: toRem(9);
              @include for-lg {
                font-size: toRem(12);
              }
            }
          }

          .meep-form-column .meep-input:last-child {
            label {
              display: block;
              top: 12px;
              left: 12px;
              font-size: 9px;
              @include for-lg {
                top: 17px;
                left: 20px;
                font-size: 12px;
              }
            }

            input {
              height: 38px;
              @include for-lg {
                height: 50px;
              }
            }
          }

          .meep-form-column {
            .md-datepicker {
              background: #f2f5f7;
              margin-top: 0px;
              padding-top: 20px;
              border-radius: 9px;

              &:after, &:before {
                display: none;
              }

              input {
                padding: toRem(12) 0 0 0;
                font-size: toRem(13);
                @include for-lg {
                  padding: toRem(15) 0 0 0;
                  font-size: toRem(17);
                }
              }

              label {
                top: 12px;
                left: 12px;
                display: block;
              }
            }
          }
        }
      }
    }

    &-preview-image {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
